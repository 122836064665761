import { IntlShape } from 'react-intl';
import { RentalUnitTypeEnum } from '../generated/graphql';
import getUnitTypeConsts from '../constants/getUnitTypeConsts';
import messages from '../constants/messages';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { BrandConfig } from '../constants/brandConfig';
import capitalize from 'lodash/capitalize';
import capitalizeEveryWord from './capitalizeEveryWord';

// by default, we just show the brand names  capitalized. but exceptions can be added to this map.
const brandLabelMap: Record<string, string> = {
    schier: 'Schiermonnikoog',
};

const getRentalUnitSummaryText = (intl: IntlShape, brandConfig: BrandConfig) => {
    const unitTypeConsts = getUnitTypeConsts(intl);
    return (
        place: string,
        type: RentalUnitTypeEnum | undefined,
        amountPersons: number,
        amountBedrooms: Maybe<number>,
        brand?: Maybe<string>
    ) => {
        let placeString = '';

        if (!brandConfig.hidePlaceInSearchTile) {
            placeString = `${place}, `;

            if (brand && brandConfig.showBrandInSearchTile) {
                const brandLabel = brandLabelMap[brand] ?? capitalize(brand);

                // show brand instead of place
                placeString = `${brandLabel}, `;
            }
        }
        const placeType = type ? `${unitTypeConsts[type].translation}, ` : '';
        const placeTypeString = `${capitalizeEveryWord(placeString)} ${placeType}`;
        const personsString = intl.formatMessage(messages.persons, { amountPersons });
        if (!amountBedrooms) {
            // "x persons"
            return placeTypeString + personsString;
        }
        // "x persons , y bedrooms"
        return `${placeTypeString}${personsString}, ${intl.formatMessage(messages.bedrooms, { amountBedrooms })}`;
    };
};

export default getRentalUnitSummaryText;
